import { EVENT_TYPE } from "~/constants";
import useRequest from "~/hooks/use-request";
import Event from "./event.model";
import SelectedEventsAPI from "./selected";
import EventStatsAPI from "./stats";
import UserMediaEventsAPI from "./user-media";

const EventsAPI = {
  selected: SelectedEventsAPI,
  userMedia: UserMediaEventsAPI,
  stats: EventStatsAPI,

  load(options: IRequestOptionsEvents): Promise<IPaginatedResponse<IModelEvent>> {
    const { request } = useRequest();
    const params = Event.params(options);

    return request
      .post<IPaginatedResponse<IResponseEvent>>("/api/event/list", { body: params })
      .then(response => Event.formatPaginated(response, Event.format));
  },

  loadById(id: string, type?: TEventType, idType?: TIdType): Promise<IModelEvent> {
    const { request } = useRequest();
    const searchType = (type === EVENT_TYPE.HIGHLIGHT || type === EVENT_TYPE.USER_CLIP || type === EVENT_TYPE.USER_HIGHLIGHT) ? "clip" : "event";
    return request
      .get<IContentResponse<IResponseEvent>>(`/api/${searchType}/get_by_id/id/${id}`, { query: { idType, media_type: type } })
      .then(response => Event.format(response.content));
  },

  loadRelatedVideos(options: { id: string; excludeVideo: boolean }): Promise<IPaginatedResponse<IModelEvent>> {
    const { request } = useRequest();
    const excludedVideoId = options.excludeVideo ? options.id : undefined;

    return request
      .get<IContentResponse<IResponseEvent>>(`/api/vod/get_related_videos?id=${options.id}`)
      .then(response => Event.mergeSuggested(response, excludedVideoId));
  },

  loadChannelProgram(url: string): Promise<IModelLinearProgram> {
    return $fetch<IModelLinearProgram>(url)
      .then(response => response);
  },

  loadGroupedSportCount(): Promise<IResponseGroupedSportCount> {
    const { request } = useRequest();
    return request
      .get<IContentResponse<IResponseGroupedSportCount>>(`/api/event/grouped_sport_count`)
      .then(response => response.content);
  },
};

export default EventsAPI;
