import { isLocal, isProduction } from "~/constants/common";

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig().public.APP_CUSTOMER;

  const gTagId = runtimeConfig.app_config.app_id_for_ga4;

  if (!gTagId) {
    if (!isProduction) {
      console.warn("Google Analytics ID is not provided");
    }

    return;
  }

  if (isLocal) {
    console.warn("Google Analitycs is disabled on local environment");
    return;
  }

  // @ts-expect-error: `dataLayer` is not defined
  window.dataLayer = window.dataLayer || [];

  function gtag(..._args: any[]) {
    // eslint-disable-next-line prefer-rest-params
    (window as any).dataLayer.push(arguments);
  }

  gtag("js", new Date());
  gtag("config", gTagId);

  useHead({
    script: [
      {
        src: `https://www.googletagmanager.com/gtag/js?id=${gTagId}`,
        async: true,
      },
    ],
  });

  return {
    provide: {
      gtag,
    },
  };
});
