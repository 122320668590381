import OneSignalVuePlugin from "@onesignal/onesignal-vue3";
import { isLocal, isProduction } from "~/constants/common";

export default defineNuxtPlugin((nuxtApp) => {
  const appConfig = useRuntimeConfig().public;
  const appId = appConfig.APP_CUSTOMER.custom_domain_url?.match(window.location.hostname)
    ? appConfig.APP_CUSTOMER.app_config.app_id_oneSignal_custom_domain_prod
    : appConfig.APP_CUSTOMER.app_config.app_id_oneSignal;

  if (!appId) {
    if (!isProduction) {
      console.warn("OneSignal App ID is not set");
    }

    return;
  }

  if (isLocal) {
    console.warn("OneSignal disabled in local environment");
    return;
  }

  nuxtApp.vueApp.use(OneSignalVuePlugin, {
    appId,
    notifyButton: {
      enable: appConfig.APP_CUSTOMER.enable_notifications_oneSignal,
    },
    // allowLocalhostAsSecureOrigin: true,
  });
});
